<template lang="pug">
  .docking-station-map-wrapper
    GmapMap.custom-map(
      ref="mapRef"
      :center="center"
      :zoom="zoom"
      map-type-id="roadmap"
      style="width: 400px; height: 300px"
    )
</template>

<script>
import customMarkerBikeAvailable from "@assets/bikesMap/available-bike.svg";
import customMarkerBikeInRide from "@assets/bikesMap/in-ride-bike.svg";
import customMarkerBikeInMaintenance from "@assets/bikesMap/in-maintenance-bike.svg";
import customMarkerBikeUnavailable from "@assets/bikesMap/unavailable-bike.svg";
import customMarkerAvailable from "@assets/dockingStationsMap/available-location.svg";
import customPolygonMarker from "@assets/dockingStationsMap/polygon-location.svg";
import customMarkerUnavailable from "@assets/dockingStationsMap/unavailable-location.svg";
import { gmapApi } from "vue2-google-maps";
import { dockingStationsStatuses } from "@utils/statuses";
import { bikesStatuses } from "@utils/statuses";
import { inBetween } from "@utils/filterUtils";
import { mapState } from "vuex";
import { checkIfNotSuperAndAdministrator } from "@utils/roles";
import { getRomaniaCoordsIfLocationIsOff } from "@utils/map";

export default {
  name: "GeoFencesMap",
  props: {
    stationsAndGeoFences: {
      type: Array,
      default: () => [],
    },
    bikes: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      markers: [],
      polygons: [],
      markerPolygons: [],
      markerBikes: [],
      zoom: 10,
      center: {
        lat: 0,
        lng: 0,
      },

      dockingStationsStatuses,
      bikesStatuses,
      inBetween,
    };
  },

  watch: {
    google() {
      this.addDockingStationsAndGeoFencesMarkers();
    },

    stationsAndGeoFences() {
      this.addDockingStationsAndGeoFencesMarkers();
    },

    bikes() {
      this.addBikesMarkers();
    },
  },

  computed: {
    google: gmapApi,

    ...mapState("auth", ["user"]),
  },

  async created() {
    await this.getBrowserLocation();
  },

  methods: {
    addDockingStationsAndGeoFencesMarkers() {
      this.markers.map((marker) => marker.setMap(null))
      this.polygons.map((polygon) => polygon.setMap(null))
      this.markerPolygons.map((markerPolygon) => markerPolygon.setMap(null))

      this.markers = []
      this.polygons = []
      this.markerPolygons = []

      if (this.stationsAndGeoFences.length == 0) {
        return;
      }
  
      this.$refs.mapRef.$mapPromise.then((map) => {
        this.stationsAndGeoFences.forEach((station) => {
          if(station.geo_fences_count > 0) {
            station.geo_fences.forEach((geo_fence) => {
              var polygoneCoords = [];
              geo_fence.geo_coordinates.forEach((coordinates) => {
                polygoneCoords.push({
                  lat: Number(coordinates.lat),
                  lng: Number(coordinates.long)
                })
              });

              var myPolygon = new google.maps.Polygon({
                paths: polygoneCoords,
                strokeColor: '#22CC22',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#22CC22',
                fillOpacity: 0.35,
                label: "yyyyy"
              });

              myPolygon.setMap(map);

              const markerPolygonContent =
              '<div id="content" class="info-box">' +
              '<div id="siteNotice">' +
              "</div>" +
              `<h4 id="firstHeading" class="text-center border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4">${geo_fence.name}</h4>` +
              '<div class="hr-custom"></div>' +
              '<div id="bodyContent" class="pl-3 pr-3 pb-1 text-center">' +
              `<h4 class="pt-2 pb-2"><b>Stația de andocare: ${station.serial_number}</b></h4>
                ` +
              "</div>" +
              "</div>";

              const markerPolygonInfoWindow = new this.google.maps.InfoWindow({
                content: markerPolygonContent,
              });

              this.polygons.push(myPolygon)

              let markerPolygon = null;
              markerPolygon = new this.google.maps.Marker({
              position: {
                lat: Number(geo_fence.geo_coordinates[0].lat),
                lng: Number(geo_fence.geo_coordinates[0].long),
              },
              map: map,
              icon: customPolygonMarker,
              draggable: false,
            });

            markerPolygon.addListener("click", () => {
              map.panTo(markerPolygon.getPosition());
              map.setZoom(18);
              markerPolygonInfoWindow.open({
                anchor: markerPolygon,
                // map,
                shouldFocus: false,
              });
            });

            this.markerPolygons.push(markerPolygon)
            });
          }

          // marker - pop up
          const contentString =
              '<div id="content" class="info-box">' +
              '<div id="siteNotice">' +
              "</div>" +
              `<h4 id="firstHeading" class="text-center border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4">${station.name}</h4>` +
              '<div class="hr-custom"></div>' +
              '<div id="bodyContent" class="pl-3 pr-3 pb-1 text-center">' +
              `<h4 class="pt-2 pb-2"><b>${station.address}</b></h4>
                 <p class="margin-0 mb-2">Geofence atașate: ${station.geo_fences_count}</p>
                  ${this.renderInfoBoxStatus(station)}
                ` +
              "</div>" +
              "</div>";

          const infoWindow = new this.google.maps.InfoWindow({
            content: contentString,
          });

          // marker
          const markerIcon = station.status === 'working' ? customMarkerAvailable : customMarkerUnavailable

          let marker = null

          marker = new this.google.maps.Marker({
            position: {
              lat: Number(station.address_lat),
              lng: Number(station.address_long),
            },
            map: map,
            icon: markerIcon,
            draggable: false,
          });

          marker.addListener("click", () => {
            map.panTo(marker.getPosition());
            map.setZoom(18);
            infoWindow.open({
              anchor: marker,
              // map,
              shouldFocus: false,
            });
          });

          this.markers.push(marker)
        });
      });
    },

    addBikesMarkers(){
      this.markerBikes.map((marker) => marker.setMap(null))
      this.markerBikes = []

      if (this.bikes.length == 0) {
        return;
      }
      
      this.$refs.mapRef.$mapPromise.then((map) => {
        this.bikes.forEach((bike) => {
          if(bike.tracker && bike.tracker.coordinates){

            // marker - pop up
            var bikeIsInStation = "-";
            if(bike.docking_station_serial_number){
              bikeIsInStation = bike.docking_station_serial_number;
            }
            var bikeIsInBayOrGeofence = "";
            if(bike.docking_station_serial_number){
              if(bike.bay_number)
                bikeIsInBayOrGeofence = `-${bike.bay_number}`;
              else 
                bikeIsInBayOrGeofence =  "(G)";
            }
             
            const contentString =
              '<div id="content" class="info-box">' +
              '<div id="siteNotice">' +
              "</div>" +
              `<h4 id="firstHeading" class="text-center border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4">Serie bicicletă: ${bike.serial_number}</h4>` +
              '<div class="hr-custom"></div>' +
              '<div id="bodyContent" class="pl-3 pr-3 pb-1 text-center">' +
              `<h5 class=" font-weight-normal pt-2 pb-2">RFID: ${bike.rfid}</h5>
                <h4 class="margin-2 mb-2"><b>Stație: ${bikeIsInStation}${bikeIsInBayOrGeofence}</b></h4>
                  ${this.renderInfoBoxBikesStatus(bike)}
                ` +
              "</div>" +
              "</div>";

            const infoWindow = new this.google.maps.InfoWindow({
              content: contentString,
            });

            const markerIcon = bike.status === 'available_in_station' ? customMarkerBikeAvailable : bike.status === 'in_ride' ? customMarkerBikeInRide : bike.status === 'under_maintenance' ? customMarkerBikeInMaintenance : customMarkerBikeUnavailable;

            let marker = null

            marker = new this.google.maps.Marker({
              position: {
                lat: Number(bike.tracker.coordinates.latitude),
                lng: Number(bike.tracker.coordinates.longitude),
              },
              map: map,
              icon: markerIcon,
              draggable: false,
            });

            marker.addListener("click", () => {
              map.panTo(marker.getPosition());
              map.setZoom(18);
              infoWindow.open({
                anchor: marker,
                // map,
                shouldFocus: false,
              });
            });

            this.markerBikes.push(marker)
          }
        });
      });
    },

    renderInfoBoxStatus(dock) {
      if (inBetween("docksStatuses", dock.status, 21, 40)) {
        return `
                 <span class="d-flex align-items-center justify-content-center dock-status">
                  <span class="oval completed"></span>
                  <span>${dockingStationsStatuses[dock.status].message}</span>
                </span>
              `;
      }

      if (inBetween("docksStatuses", dock.status, 41, 60)) {
        return `
                 <span class="d-flex align-items-center justify-content-center dock-status">
                  <span class="oval dock-icon unavailable"></span>
                  <span>${dockingStationsStatuses[dock.status].message}</span>
                </span>
              `;
      }

      if (inBetween("docksStatuses", dock.status, 1, 20)) {
        return `
                 <span class="d-flex align-items-center justify-content-center dock-status">
                  <span class="oval dock-icon pending"></span>
                  <span>${dockingStationsStatuses[dock.status].message}</span>
                </span>
              `;
      }
    },

    renderInfoBoxBikesStatus(bike) {
      if (inBetween("bikesStatuses", bike.status_code.status, 21, 40)) {
              return `
                      <span class="d-flex align-items-center justify-content-center bike-status">
                        <span class="oval completed"></span>
                        <span>${bikesStatuses[bike.status_code.status].message}</span>
                      </span>
                    `;
            }

      if (inBetween("bikesStatuses", bike.status_code.status, 41, 60) || inBetween("bikesStatuses", bike.status_code.status, 0, 1)) {
        return `
                <span class="d-flex align-items-center justify-content-center bike-status">
                  <span class="oval bike-icon unavailable"></span>
                  <span>${bikesStatuses[bike.status_code.status].message}</span>
                </span>
              `;
      }

      if (inBetween("bikesStatuses", bike.status_code.status, 2, 20)) {
        return `
                <span class="d-flex align-items-center justify-content-center bike-status">
                  <span class="oval bike-icon pending"></span>
                  <span>${bikesStatuses[bike.status_code.status].message}</span>
                </span>
              `;
      }
    },

    async getBrowserLocation() {
      // if   - user is not super admin or admin, center the map to one dock belonging to the same cost center
      // else - point the admin and super admin to the browser location
      if (checkIfNotSuperAndAdministrator() && this.stationsAndGeoFences.length > 0) {
        this.center.lat = Number(this.stationsAndGeoFences[0].attributes.address_lat);
        this.center.lng = Number(this.stationsAndGeoFences[0].attributes.address_long);
      } else {
        navigator.geolocation.getCurrentPosition((location) => {
          this.center.lat = location.coords.latitude;
          this.center.lng = location.coords.longitude;
        });

        // if location is not working or turned off
        const romaniaCoords = await getRomaniaCoordsIfLocationIsOff()
        if (romaniaCoords) {
          this.center.lat = romaniaCoords.coords.latitude
          this.center.lng = romaniaCoords.coords.longitude
          this.zoom = 7
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.customMarker {
  opacity: 0.4;
  pointer-events: none;
}

.custom-map {
  width: 100% !important;
  height: 70vh !important;
}

::v-deep .dock-icon {
  img {
    height: 14px;
  }
}

// override gmap info box
::v-deep .gm-style .gm-style-iw-c { // TODO: handle info box styling on addresses on locations the map
  padding: 0;
  max-width: 330px !important;
}

::v-deep .gm-style-iw-d {
  overflow: unset !important;
}

// info box close btn
::v-deep .gm-ui-hover-effect {
  top: -2px !important;
  right: -2px !important;

  img {
    background: #adb5bd !important;
    border-radius: 2px;
  }
}

::v-deep #bodyContent {
  p {
    margin: 0;
  }
}
</style>
