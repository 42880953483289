<template lang="pug">
  .edit-docking-station
    GoogleMaps(
      @markerDragEnd="handleMarkerDragEnd"
      :dialogName="'EditDockingStation'"
    )
    el-form(
      :model="form"
      v-if="dockingStation"
      v-loading="loadingForm"
      ref="EditDockingStationForm"
      :rules="addDockingStationRules"
      @submit.native.prevent
    )
      el-row(:gutter="24")
        el-col(:span="8")
          el-form-item(
            prop="name"
            :label="$t('label.docking_name')"
          )
            el-input(
              v-model="form.name"
              name="name"
            )
        el-col(:span="4")
          el-form-item(
            prop="address_long"
            :label="$t('label.longitude')"
          )
            el-input(
              v-model.number="form.address_long"
              name="address_long"
              type="number"
            )
        el-col(:span="4")
          el-form-item(
            prop="address_lat"
            :label="$t('label.latitude')"
          )
            el-input(
              v-model.number="form.address_lat"
              name="address_lat"
              type="number"
            )
        el-col.address(:span="8")
          el-form-item(
            prop="address"
            :label="$t('label.address')"
          )
            el-autocomplete(
              ref="autocomplete"
              v-model="form.address"
              name="address"
              :trigger-on-focus="false"
              @select="handleSelectAddress"
              :fetch-suggestions="addressSearchResultsDebounce"
            )
          el-checkbox-group(
                v-model="updateAddress"
              )
            el-checkbox.mb-2.text-sm.text-primary(
              prop="update_address"
              name="update_address"
              :label="$t('dialogs.docking_stations_list.update_address')"
            )
      el-row(:gutter="24")
        el-col(:span="8")
          el-form-item(
            prop="serial_number"
            :label="$t('common.serial_number')"
          )
            el-input(
              v-model="form.serial_number"
              name="serial_number"
            )
        el-col(
          :span="8"
        )
          el-form-item(
            prop="cost_center_id"
            :label="$t('common.cost_center')"
          )
            el-select(
              v-model='form.cost_center_id'
              filterable
              :placeholder="$t('placeholder.enter_cost_center_id')"
            )
              el-option(
                v-for='costCenter in costCenters'
                :key='costCenter.id'
                :label='costCenter.name'
                :value='costCenter.id'
              )
        el-col(
          :span="4"
        )
          el-form-item(
            prop="rent_tariff_id"
            :label="$t('common.price_plan')"
          )
            el-select(
              v-model='form.rent_tariff_id'
              filterable
              :placeholder="$t('placeholder.select_price_plan')"
            )
              el-option(
                v-for='tariff in rentTariffs'
                :key='tariff.id'
                :label='tariff.name'
                :value='tariff.id'
              )
        el-col(
          :span="4"
        )
          el-form-item(
            prop="bays_count"
            :label="$t('label.available_bays_number')"
          )
            el-select(
              v-model='form.bays_count'
              filterable
              :placeholder="$t('placeholder.select_bays')"
            )
              el-option(
                v-for='bay in numberOfExistingBays'
                :key='bay'
                :label='bay'
                :value='bay'
              )
      el-row
        el-col(style="display: flex;flex-direction: row;justify-content: space-between;")
          el-form-item(
            prop="status"
            :label="$t('label.dock_status')"
          )
          el-form-item(
            style="margin-right:15px !important"
            prop="bikes_rental_is_disabled"
            :label="$t('label.bikes_rental_status')"
          )
        el-col(style="display: flex;flex-direction: row;justify-content: space-between;")
          div(style="display: flex;flex-direction: row;")
            el-radio(v-model='form.status' label="working") {{ $t('dialogs.docking_stations_list.available_status') }}
            el-radio(v-model='form.status' label="under_maintenance") {{ $t('dialogs.docking_stations_list.maintenance_status') }}
          div(style="display: flex;flex-direction: row;")
            el-radio(v-model='form.bikes_rental_is_disabled' label="false") {{ $t('dialogs.docking_stations_list.allow_bikes_rental') }}
            el-radio(v-model='form.bikes_rental_is_disabled' label="true") {{ $t('dialogs.docking_stations_list.disallow_bikes_rental') }}

    el-row.text-center
      el-button.dark-blue-btn.mt-2.pop-up-btn(
        @click="handleEditNewDockingStation('EditDockingStationForm')"
        :loading="loadingSubmitBtn"
      ) {{ $t('common.edit') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import GoogleMaps from "@components/_shared/GoogleMaps";
import _ from "lodash";
import { docksViewValidations } from "@utils/formValidations/docksViewValidations";
import { customValidations } from "@utils/customValidators";
import { setObjectValues } from "@utils/generalUtils";
import Vue from "vue";

export default {
  name: "EditDockingStation",
  mixins: [docksViewValidations, customValidations],

  components: {
    GoogleMaps,
  },

  data() {
    return {
      rentTariffs: [],
      costCenters: [],
      numberOfExistingBays: 100,
      selectedPlaceID: null,
      dockObject: [],
      form: {
        name: null,
        address_long: null,
        address_lat: null,
        address: null,
        serial_number: null,
        bays_count: null,
        status: null,
        rent_tariff_id: null,
        cost_center_id: null,
        bikes_rental_is_disabled: null,
      },
      loadingForm: false,
      loadedForm: false,
      loadingSubmitBtn: false,
      setObjectValues,
      updateAddress: false,
      isLoading: false
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("dockingStation", ["dockingStation"]),
  },

  watch: {
    dockingStation() {
      this.handleGetFormData();
      this.updateMapPinFromDockCoordinates();
    },

    'form.address_lat': {
      handler(newValue, oldValue) {
        if (this.isLoading && oldValue !== newValue) {
          this.isLoading = false;
          this.setMapCoordinates({ lat: newValue, lng: Number(this.form.address_long) });
          this.isLoading = true;
        }
      }
    },

    'form.address_long': {
      handler(newValue, oldValue) {
        if (this.isLoading && oldValue !== newValue) {
          this.isLoading = false;
          this.setMapCoordinates({ lat: Number(this.form.address_lat), lng: newValue });
          this.isLoading = true;
        }
      }
    }
  },

  async created() {
    this.loadingForm = true;
    this.handleGetFormData();
    this.updateMapPinFromDockCoordinates();
    await this.handleGetTariffs();
    await this.handleGetCostCenters();
    await this.handleGetDock();
    this.loadingForm = false;
    this.isLoading = true;
  },

  methods: {
    ...mapActions("map", ["getPlaceByKeyword", "getPlaceByID"]),

    ...mapActions("rentTariff", ["getTariffs"]),

    ...mapActions("dockingStation", ["updateDock"]),

    ...mapActions("costCenter", ["getCostCenters"]),

    ...mapActions("dockingStation", ["getDock"]),

    ...mapActions("bike", ["getBike"]),

    ...mapActions("bike", ["switchBikeStatus"]),

    ...mapMutations("bike", ["setShouldFetchBikes"]),

    ...mapMutations("dialog", ["hideEditDockingStationDialog"]),

    ...mapMutations("map", ["setMapCoordinates"]),

    ...mapMutations("dockingStation", ["setShouldFetchDocks"]),

    async updateMap(){
      let addressObj = {
        address_line_1: this.form.address,
      };

      Vue.$geocoder.setDefaultCountryCode('RO');

      await Vue.$geocoder.send(addressObj, response => {
        this.handleSelectAddress(response.results[0]);
      });
    },

    clearForm() {
      this.setObjectValues(this.form, "");
    },

    handleGetFormData() {
      this.form = { ...this.dockingStation }
      this.form.cost_center_id = this.dockingStation.cost_center_id.toString();
      console.log(this.dockingStation)
      this.form.bikes_rental_is_disabled = this.dockingStation.bikes_rental_is_disabled.toString();
    },

    async handleGetTariffs() {
      let params = "";
      const response = await this.getTariffs(params);
      this.rentTariffs = response.data;
    },

    async handleGetDock() {
      this.dockObject = await this.getDock(this.dockingStation.id);
    },

    // TODO: uncomment this if we need it in the future
    // async handleBikesStatus(status) {
    //   let bays = this.dockObject.bays;

    //   bays.forEach(bay => {
    //     if (status === 'under_maintenance') {
    //       let event_name = "make_unavailable";

    //       let params = {
    //         id: bay.bike.id * 1,
    //         event_name,
    //       };

    //       this.switchBikeStatus(params).then(() => {
    //         this.getBike(bay.bike.id * 1);
    //         this.loading = false;
    //       });
    //       this.setShouldFetchBikes(true);

    //     } else if (status === 'working') {
    //       let event_name = "make_available";
    //       let params = {
    //         id: bay.bike.id * 1,
    //         event_name,
    //       };

    //       this.switchBikeStatus(params).then(() => {
    //         this.getBike(bay.bike.id * 1);
    //         this.loading = false;
    //       });
    //       this.setShouldFetchBikes(true);
    //     }
    //   });
    // },

    async handleGetCostCenters() {
      let params = ""
      const response = await this.getCostCenters(params)
      this.costCenters = response.data
    },

    async handleGetPlaceData(address, autocompleteCallback) {
      let placesDescription = [];

      // on typing in the field
      if (address !== "") {
        let query = `?keyword=${address}`;
        const places = await this.getPlaceByKeyword(query);

        placesDescription = places.map((place) => {
          return { value: place.description, place_id: place.place_id };
        });

        autocompleteCallback(placesDescription);
      }
    },

    addressSearchResultsDebounce: _.debounce(function (
            address,
            autocompleteCallback
        ) {
          this.handleGetPlaceData(address, autocompleteCallback);
        },
        300),

    async handleSelectAddress(item) {
      this.selectedPlaceID = item.place_id;
      const placeDetails = await this.getPlaceByID(item.place_id);

      this.form.address_lat = placeDetails.lat;
      this.form.address_long = placeDetails.lng;
      let coordinates = {
        lat: placeDetails.lat,
        lng: placeDetails.lng,
      };

      await this.setMapCoordinates(coordinates);
    },

    async handleMarkerDragEnd(address, markerPosition) {
      await this.setMapCoordinates(markerPosition);
      if(this.updateAddress){
        this.form.address = address;
        this.$refs.autocomplete.focus();
      }
      this.form.address_lat = markerPosition.lat;
      this.form.address_long = markerPosition.lng;
    },

    updateMapPinFromDockCoordinates() {
      this.setMapCoordinates({
        lat: Number(this.form.address_lat),
        lng: Number(this.form.address_long),
      });
    },

    handleEditNewDockingStation(formName) {
      this.loadingSubmitBtn = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          let params = { ...this.form };

          this.updateDock(params)
              .then(() => {
                // this.handleBikesStatus(this.form.status);
                this.hideEditDockingStationDialog();
                this.setShouldFetchDocks(true);
              })
              .finally(() => {
                this.loadingSubmitBtn = false;
              });
        } else {
          this.loadingSubmitBtn = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-docking-station {
  h4 {
    overflow: hidden;
    color: white;
    font-family: Montserat, sans-serif;
    font-weight: 600;
    letter-spacing: 0.12px;
    line-height: 19px;
  }
}

.address {
  .el-autocomplete {
    width: 100%;
  }
}

.el-select {
  width: 100%;
}
</style>
