<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='rides'
  )
    el-table-column(
      width="55"
    )
      template(slot-scope='scope')
        span.pre-line(
          @click="adminResource() ? copyToClipBoard(scope.row.id) : ''"
          :class="adminResource() ? ['text-underline', 'cursor-pointer'] : ''"
          :title="adminResource() ? `Copiază id cursă - ${scope.row.id}` : ''") {{ scope.$index + 1}}
    el-table-column(
      prop='start_at'
      :label="$t('label.starting_date')"
    )
      template(slot-scope='scope')
        span.pre-line(v-if="scope.row.start_at") {{ computeDateFormat(scope.row.start_at) }}
        span.pre-line(v-else) {{ computeDateFormat(scope.row.created_at) + " *" }}
    el-table-column.pre-line(
      prop='start_docking_station'
      :label="$t('label.start_dock_end_dock')"
    )
      template(slot-scope='scope')
        .docks.pre-line
          span {{ scope.row.start_docking_station_name}}
          span(v-if="!scope.row.start_docking_station_name") -

          span.text-black.cursor-pointer(
            v-if="scope.row.start_geo_fence_id != null"
            :title="adminResource() ? `Cursa a început în geofence-ul cu id ${scope.row.start_geo_fence_id}, nume ${scope.row.start_geo_fence_name}` : `Cursa a început în geofence-ul ${scope.row.start_geo_fence_name}`"
          ) {{ " (G) " }}

          span {{ "/ \n" }}

          span {{ scope.row.end_docking_station_name }}
          span(v-if="!scope.row.end_docking_station_name") -

          span.text-black.cursor-pointer(
            v-if="scope.row.end_geo_fence_id != null"            
            :title="adminResource() ? `Cursa s-a finalizat în geofence-ul cu id ${scope.row.end_geo_fence_id}, nume ${scope.row.end_geo_fence_name}` : `Cursa s-a finalizat în geofence-ul ${scope.row.end_geo_fence_name}`"
          ) {{ " (G) " }}
    el-table-column(
      prop='duration_seconds'
      :label="$t('common.bike_serial_number')"
    )
      template(slot-scope='scope') 
        span.pre-line.text-underline.cursor-pointer(
          v-if="scope.row.bike_id"
          @click="handleOpenViewBikeModal(scope.row.bike_id)"
        ) {{ scope.row.bike_serial_number }}
        span(v-else) -
    el-table-column(
      prop='duration_seconds'
      :label="$t('common.ride_duration')"
    )
      template(slot-scope='scope') {{ computeRideDuration(scope.row.duration_seconds) }}
    el-table-column(
      prop='price'
      :label="$t('common.ride_price_paid')"
    )
      template(slot-scope='scope')
        span.pre-line {{ $n(scope.row.price, 'currency') + "/ \n" + $n(scope.row.already_paid_ammount,'currency') }}
    el-table-column(
      prop='status'
      :label="$t('common.ride_status')"
    )
      template(slot-scope='scope')
        .status-wrapper.pre-line(
          v-if="inBetween('ridesStatuses', scope.row.status, 21, 40)"
        )
          .status
            span.oval.completed
          span {{ scope.row.status_object.label  }}
          span {{ computePaidStatus(scope.row.price, scope.row.already_paid_ammount) }}

        .status-wrapper(v-if="inBetween('ridesStatuses', scope.row.status, 41, 60)")
          .status
            span.oval.cancelled
            span {{ scope.row.status_object.label }}
          span {{ computePaidStatus(scope.row.price, scope.row.already_paid_ammount) }}

        .status-wrapper(v-if="inBetween('ridesStatuses', scope.row.status, 1, 20)")
          .status
            span.oval.pending
            span {{ scope.row.status_object.label  }}
    el-table-column(
      prop='flag'
      :label="$t('common.flagged')"
    )
      template(slot-scope='scope')
        span.pre-line.break-word(v-if="scope.row.flag !== 'no_problems'") 🚩 {{ scope.row.flag }}
        span(v-else) --
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
    )
      template(slot-scope='scope')
        img.cursor-pointer.ml-2(
          src="@assets/preview.svg"
          @click="handleViewRideDetails(scope.row)"
        )
</template>

<script>
import { filterUtils, inBetween } from "@utils/filterUtils";
import { ridesStatuses } from "@utils/statuses";
import { mapState, mapActions, mapMutations } from "vuex";
import store from "@state/store";
import { roles } from "@utils/roles";

export default {
  name: "CustomerRideHistoryViewTable",
  mixins: [filterUtils],

  props: {
    rides: {
      type: Array
    }
  },

  data() {
    return {
      inBetween,
      ridesStatuses
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("dialog", [
      "showViewRideDetailsDialog",
      "showViewBikeDetailsDialog"
    ]),
    ...mapMutations("ride", ["setRide"]),
    ...mapMutations("bike", ["setBike"]),
    ...mapActions("bike", ["getBike"]),

    handleViewRideDetails(ride) {
      this.showViewRideDetailsDialog();
      this.setRide(ride);
    },

    async handleOpenViewBikeModal(bike_id) {
      const response = await this.getBike(bike_id);
      this.setBike(response);
      this.showViewBikeDetailsDialog();
    },

    adminResource() {
      return (
          this.user &&
          [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
    },

    copyToClipBoard(textToCopy) {
      const tmpTextField = document.createElement("textarea")
      tmpTextField.textContent = textToCopy
      tmpTextField.setAttribute("style","position:absolute; right:200%;")
      document.body.appendChild(tmpTextField)
      tmpTextField.select()
      tmpTextField.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand("copy")
      tmpTextField.remove();

      this.$message({
        type: "success",
        message: "Id cursă copiat",
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.status-wrapper {
  display: flex;
  flex-direction: column;

  & > span {
    margin-left: 22px;
  }

  .status {
    display: flex;
    align-items: center;
  }

  span {
    display: block;
  }
}
</style>
