<template lang="pug">
  .rides-view
    h3 {{ $t('rides.rides') }}
    .filters
      .switch-buttons
        .switch
          el-switch(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.RIDE, ['is_station_different_from_start_to_end'])"
            v-model="station_is_different"
            active-color="#2564F0"
            inactive-color="#dbe1fa"
            :active-text="$t('rides.station_is_different')"
          )  
      // filter by date range
      FilterDatePicker(
        v-model="dateRange"
        v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.RIDE, ['by_date_range'])"
      )

      // filter by status
      FilterStatus(
        :name="$t('common.status_filter')"
        :statuses="ridesStatuses"
        v-model="selectedStatus"
      )
      FilterCostCenterDropdown(v-model="selectedCostCenterId")
    .rides-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="showItemDynamically(user, dynamicViewsConstants.resource_names.RIDE, ['by_keyword'])"
        )
          el-button.el-icon-refresh-left.mr-2.cursor-pointer(
            style="font-size: 35px; margin-right: 0px;"
            :class="{disabled: generatePausedForSeconds}"
            :loading="generatePausedForSeconds"
            @click="reloadData()")
      RidesViewTable(
        v-loading="loading"
        :rides="rides"
        :selectedStatus="selectedStatus"
      )
    // TODO: change :activeName to selectedStatus
    Pagination(
      v-if="selectedStatus && selectedStatus !== ''"
      :view="'rides'"
      :activeName="selectedStatus"
      :getStationIsDifferentFromStartToEnd="station_is_different"
      :search="search"
      :reload="reload"
      :dateRange="dateRange"
      :costCenterId="selectedCostCenterId"
      @viewData="handleGetRides"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import { filterUtils } from "@utils/filterUtils";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { dateRangeShortcuts, disabledFutureDates } from "@utils/dateRange";
import { routerUtils } from "@utils/router";
import Pagination from "@components/_shared/Pagination";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import RidesViewTable from "@components/rides/RidesViewTable";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";
import FilterStatus from "@components/_shared/FilterStatus";

export default {
  mixins: [filterUtils, routerUtils, general],
  components: {
    SearchWithButtons,
    Pagination,
    FilterDatePicker,
    FilterCostCenterDropdown,
    RidesViewTable,
    FilterStatus,
  },

  data() {
    return {
      selectedStatus: "all",
      ridesStatuses: [
        { name: "All", value: "all" },
        { name: "Initiated rides", value: "waiting_for_bike_to_be_unlocked" },
        { name: "In progress", value: "in_progress" },
        { name: 'Requested to be canceled', value: "requested_to_be_canceled"},
        // { name: "Flagged", value: "flagged" },
        { name: "Closed by Admin", value: "canceled_by_admin" },
        { name: "Completed", value: "completed" },
        { name: "Errors", value: "errors" },
      ],
      station_is_different: false,
      search: "",
      dateRange: [],
      selectedCostCenterId: "",
      rides: [],
      pickerOptions: {
        disabledDate(time) {
          return disabledFutureDates(time);
        },
        shortcuts: dateRangeShortcuts,
      },
      loading: false,
      reload: false,
      generatePausedForSeconds: false,
      dynamicViewsConstants,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  watch: {
    // reload() {
    //   if(reload) {
    //     this.handleLoading();
    //   }
    // }
  },

  methods: {
    handleGetRides(data) {
      this.rides = data;
    },

    handleLoading(loading) {
      this.loading = loading;
      if(!loading) {
        setTimeout(() => this.generatePausedForSeconds = loading, 5000);
      }else {      
        this.generatePausedForSeconds = loading
      }
      this.reload = false;
    },

    reloadData(){
      this.reload = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.docks {
  span {
    display: block;
  }
}

.images {
  display: flex;
  align-items: center;

  .img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 8px;
  }

  .plus-icon {
    margin-left: 4px;
  }
}
</style>
