<template lang="pug">
  .view-bike-rides
    .view-bike-statistics(
      v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['statistics'])"
      )
      el-row.center.details-bold(
        :gutter="50"
        type="flex"
        justify="center"
        style="margin-top: 15px;"
      )
        el-col(:span="3")
          span {{ $t('bikes.statistics.total_rides') }}

        el-col(:span="5")
          span {{ $t('bikes.statistics.completed_rides') }}

        el-col(:span="6")
          span {{ $t('bikes.statistics.canceled_by_admin') }}

        el-col(:span="6")
          span {{ $t('bikes.statistics.rides_with_hardware_error') }}
          .tooltip-container
            span.tooltiptext.break-word(v-html="formatHardwareErrorText")
            span.tooltip-trigger
              Tooltip(:tooltipText="formatHardwareErrorText")
        el-col(:span="5")
          span {{ $t('bikes.statistics.rides_with_customer_errors') }}
          .tooltip-container
            span.tooltiptext.break-word(v-html="formatCustomerErrorsText")
            span.tooltip-trigger
              Tooltip(:tooltipText="formatCustomerErrorsText")
      el-row.center(
        :gutter="50"
        type="flex"
        justify="center"
        style="margin-top: 10px; margin-bottom: 25px; font-size:20px"
      )
        el-col(:span="3")
          span.break-word {{ statistics.no_of_total_rides }}

        el-col(:span="5")
          span.break-word {{ statistics.no_of_completed_rides }}

        el-col(:span="6")
          span.break-word {{ statistics.no_of_rides_canceled_by_admin }}

        el-col(:span="6")
          span.break-word {{ statistics.no_of_rides_with_hardware_errors }}
        el-col(:span="5")
          span.break-word {{ statistics.no_of_rides_with_customer_errors }}
    .bike-history(
      v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['show_history_rides'])"
      )
      el-table.bike_history_table(
        :data="bike_history"
        v-loading="loading"
        :gutter="0"
        height="250"
        style="width: 100%;"
      )
        el-table-column(
          width="55"
        )
          template(slot-scope='scope')
            span.pre-line {{ scope.$index + 1}}
        el-table-column(
          width="180"
          prop="created_at"
          :label="$t('label.date')"
        )
          template(slot-scope='scope')
            span {{ computeDateFormat(scope.row.created_at) }}
        el-table-column(
          prop="customer"
          :label="$t('common.last_customer')"
        )
          template(slot-scope='scope')
            span.text-underline.cursor-pointer( 
              v-if="scope.row.customer"
              @click="openInNewTab(scope.row.customer.id)"
              ) {{ scope.row.customer.first_name + " " + scope.row.customer.last_name }}
            span(v-else) -
        //- el-table-column(
        //-   width="100"
        //-   prop="id"
        //-   :label="$t('label.last_ride_id')"
        //- )
        //-   template(slot-scope='scope')
        //-     span(@click="scope.row.id") {{ scope.row.id }}
        el-table-column.pre-line(
          width="195"
          prop='start_docking_station'
          :label="$t('label.start_dock_end_dock')"
        )
          template(slot-scope='scope')
            .docks.pre-line
              span(v-if="scope.row.start_docking_station_name") {{ scope.row.start_docking_station_name }}
              span(v-if="!scope.row.start_docking_station_name") -
              
              span.text-black.cursor-pointer(
                v-if="scope.row.start_geo_fence_id != null"
                :title="adminResource() ? `Cursa a început în geofence-ul cu id ${scope.row.start_geo_fence_id}, nume ${scope.row.start_geo_fence_name}` : `Cursa a început în geofence-ul ${scope.row.start_geo_fence_name}`"
              ) {{ " (G) " }}

              span {{ "/ \n" }}

              span {{ scope.row.end_docking_station_name }}
              span(v-if="!scope.row.end_docking_station_name") -

              span.text-black.cursor-pointer(
                v-if="scope.row.end_geo_fence_id != null"            
                :title="adminResource() ? `Cursa s-a finalizat în geofence-ul cu id ${scope.row.end_geo_fence_id}, nume ${scope.row.end_geo_fence_name}` : `Cursa s-a finalizat în geofence-ul ${scope.row.end_geo_fence_name}`"
              ) {{ " (G) " }}
        el-table-column(
          prop="status"
          :label="$t('label.last_ride_status')"
        )
          template(slot-scope='scope')
            .status-wrapper(
              v-if="inBetween('ridesStatuses', scope.row.status, 21, 40)"
            )
              .status
                span.oval.completed
                //- span {{ ridesStatuses[scope.row.status].message }}
                span {{ scope.row.status_object.label }}
            .status-wrapper(v-if="inBetween('ridesStatuses', scope.row.status, 41, 60)")
              .status
                span.oval.cancelled
                span {{ scope.row.status_object.label }}
            .status-wrapper(v-if="inBetween('ridesStatuses', scope.row.status, 1, 20)")
              .status
                span.oval.pending
                span {{ scope.row.status_object.label }}
      DialogTablePagination(
        :activeName="activeName"
        @viewData="handleGetData"
        @handleTableLoading="handleLoading"
      )
</template>

<script>
import DialogTablePagination from "@components/_shared/DialogTablePagination";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { ridesStatuses } from "@utils/statuses";
import { roles } from "@utils/roles";
import store from "@state/store";
import { routerUtils } from "@utils/router";
import { mapState, mapActions, mapMutations } from "vuex";
import Tooltip from '@components/_shared/Tooltip';
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  name: "BikeRidesHistory",
  mixins: [filterUtils, routerUtils, general],

  components: { 
    DialogTablePagination,
    Tooltip,
  },

  props: {
    activeName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      bike_history: [],
      statistics: {
        no_of_total_rides: 0,
        no_of_completed_rides: 0,
        no_of_rides_canceled_by_admin: 0,
        no_of_rides_with_hardware_errors: 0,
        failed_unlocking_bike_because_of_hardware_error: 0,
        station_is_not_available: 0,
        no_of_rides_with_customer_errors: 0,
        holding_money_failed_authorization_required: 0,
        failed_because_user_did_not_pick_up_bike: 0,
        failed_because_user_canceled_before_pick_up_bike: 0
      },

      hardwareErrorText: "0 - failed unlocking bike because of hardware error, 0 - station is not available",
      customerErrorsText: "0 - holding money failed authorization required, 0 - failed because user did not pick up bike, 0 - failed because user canceled before pick up bike",

      loading: false,

      inBetween,
      ridesStatuses,

      dynamicViewsConstants
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("bike", ["bike"]),

    formatHardwareErrorText(){
      this.hardwareErrorText = `${this.statistics.failed_unlocking_bike_because_of_hardware_error} - failed unlocking bike because of hardware error, ${this.statistics.station_is_not_available} - station is not available`;
      return this.hardwareErrorText.split(',').join('<br>');
    },

    formatCustomerErrorsText(){
      this.customerErrorsText = `${this.statistics.holding_money_failed_authorization_required} - holding money failed authorization required, ${this.statistics.failed_because_user_did_not_pick_up_bike} - failed because user did not pick up bike, ${this.statistics.failed_because_user_canceled_before_pick_up_bike} - failed because user canceled before pick up bike`;
      return this.customerErrorsText.split(',').join('<br>');
    }
  },

  async created() {
    await this.handleGetCustomersStatistics();
  },

  methods: {
    ...mapMutations("dialog", [
      "hideViewPasswordValidationDeleteDialog",
      "hideViewBikeDetailsDialog"
    ]),

    ...mapActions("bike", ["getBikeStatistics"]),

    handleGetData(data) {
      this.bike_history = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },

    openInNewTab(id) {
      let route = this.$router.resolve({path: `customers/${id}`});
      window.open(route.href, '_blank');
    },

    async handleGetCustomersStatistics() {
      this.statistics = await this.getBikeStatistics(this.bike.id);
    },

    adminResource() {
      return (
          this.user &&
          [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
    },

  },
};
</script>

<style lang="scss" scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 3px;
  // border-style: solid;
  // border: 1px solid black;
  // box-shadow: 1px 4px 5px 4px rgb(238, 238, 238);
  box-shadow: 0px 0px 5px 3px rgb(238, 238, 238);
  padding: 10px;
  padding-left: 15px; /* Add left padding to create a gap */
  position: absolute;
  z-index: 100;
  bottom: 110%; /* Position the tooltip above the text */
  margin-left: -200px;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: left;
  font-family: "AvenirNextW10Medium", sans-serif;
}

.tooltip-container:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-trigger {
  cursor: pointer;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -9px;
  border-width: 7px;
  border-style: solid;
  border-color: rgb(255, 255, 255) transparent transparent transparent;
}

.status-wrapper {
  display: flex;

  .status {
    display: flex;
    align-items: center;
  }

  span {
    display: block;
  }
}
</style>
