export const geoFencesViewValidations = {
  data() {
    return {
      geoFencesViewFormRules: {
        docking_station_id: [
          {
            required: false,
            trigger: "blur",
          },
        ],
        cost_center_id: [
          {
            required: true,
            message: this.$t("common.name"),
            trigger: "blur",
          },
        ],
      },
    };
  },
};
