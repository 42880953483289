<template lang="pug">
  .geo-fences-map
    h3 {{ $t('geo_fences_map.geo_fences_map') }}
      .geo-fence-filters
        .filters
          .switch-buttons
            .switch
              el-switch(
                v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['index']) && showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['show_geo_fences_bikes'])"
                v-model="showBikes"
                :disabled="!costCenterId"
                :loading="loadingBikes"
                active-color="#2564F0"
                inactive-color="#dbe1fa"
                :active-text="$t('geo_fences_map.show_bikes')"
              ) 
        .docking-stations-filter
          span.filter-title {{ $t('common.docking_station') }}
          el-select.w-100(
            v-model='docking_station_id'
            filterable
            :placeholder="$t('placeholder.select_dock')"
          )
            el-option(
              v-for='dock in docks'
              :key='dock.id'
              :label='dock.name'
              :value="dock.value !== '' ? dock.id : dock.value"
            )
        .cost-centers-filter(v-if="computeCostCenterIDFilter")
          span.filter-title {{ $t('common.cost_center_name') }}
          el-select.w-100(
            v-model='costCenterId'
            filterable
            :placeholder="$t('placeholder.select_cost_center')"
            @change="handleGetDocks"
          )
            el-option(
              v-for='costCenter in cost_centers'
              :key='costCenter.id'
              :label='costCenter.name'
              :value='costCenter.id'
            )
      .geo-fence-button
        el-button.dark-blue-btn.mt-2.pop-up-btn(
          style="margin-right: 0px; !important"
          @click="handleGetGeofencesAndBikes()"
          :loading="loadingSubmitButton"
        ) {{ $t('geo_fences_map.generate_button') }}
    .geo-fences-wrapper
      GeoFencesMap(
        v-if="loadedCostCenters"
        :stationsAndGeoFences="stationsAndGeoFences"
        :bikes="bikes"
      )
      div.bikes-container
        span(
          v-if="bikesWithoutLocation.length > 0 && bikesWithoutLocation.length < 2"
          style="padding: 5px 10px;color: #dd2233;"
        ) {{ `o bicicletă fără locație: ` }}
        span(
          v-if="bikesWithoutLocation.length > 1"
          style="padding: 5px 10px;color: #dd2233;"
        ) {{ `${bikesWithoutLocation.length} biciclete fără locație: ` }}
        div(v-for="(bike, i) in bikesWithoutLocation" :key="i" class="bike-item")
          span {{ bike.serial_number }}
</template>

<script>
import { geoFencesViewValidations } from "@utils/formValidations/geoFencesViewValidations";
import GeoFencesMap from "@components/docking_stations/geo_fences/GeoFencesMap.vue";
import { mapActions, mapState } from "vuex";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import { roles } from "@utils/roles";
import store from "@state/store";

export default {
  mixins: [general, geoFencesViewValidations],

  components: {
    FilterCostCenterDropdown,
    GeoFencesMap
  },

  data() {
    return {
      bikesWithoutLocation: [],
      showBikes: false,
      loadingBikes: false,
      bikes: [],
      cost_centers: [],
      docks: [],
      messages: [],
      bays: [],
      stationsAndGeoFences: [],
      docking_station_id: null,
      costCenterId: null,

      ridesInProgress: 0,
      availableBikes: 0,
      nrOfClients: 0,
      docks: [],
      workingDocks: [],
      underMaintenanceDocks: [],
      loadingSubmitButton: false,

      loadedCostCenters: false,

      dynamicViewsConstants
    }
  },

  watch: {
    costCenterId: {
      handler() {
        this.docking_station_id = null;
        this.showBikes = false;
      }
    },
  },

  computed: {
    ...mapState("auth", ["user"]),

    computeCostCenterIDFilter() {
      return (
          this.user &&
          [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
    },
  },

  async created() {
    if (this.computeCostCenterIDFilter) {
      await this.handleGetCostCenters()
    }else{
      if(this.user.selected_cost_center){
        this.costCenterId = this.user.selected_cost_center.id;
        await this.handleGetDocks(this.costCenterId);
      }
      this.loadedCostCenters = true;
    }
  },

  methods: {
    ...mapActions('dockingStation', ["getDocks", "getGeoFences", "getBikes"]),
    ...mapActions('costCenter', ["getCostCenters"]),

    async handleGetCostCenters() {
      let response = await this.getCostCenters("?page_size=1000")
      this.cost_centers = response.data
      this.loadedCostCenters = true
    },

    async handleGetDocks(id) {
      let computedUrl = `?page_size=1000&by_cost_center_id=${id}`
      let response = await this.getDocks(computedUrl)
      this.docks = response.data
      this.docks.unshift({
        id: 0,
        name: "All",
        value: "All",
      });

      this.docking_station_id = this.docks[0].id;
    },

    async handleGetGeofencesAndBikes(){
      this.handleGetGeoFences();
      if (this.showBikes){
        this.handleGetBikes();
      }else {
        this.bikes = [];
        this.bikesWithoutLocation = [];
      }
    },

    async handleGetGeoFences() {
      this.loadingSubmitButton = true;
      var stationsAndGeoFencesArray = [];

      if(this.costCenterId == "" || this.costCenterId == null || this.docking_station_id == null) {
        this.$message({
          type: "error",
          message: this.$t("dialogs.geo_fences.cost_center_not_selected"),
        });
        this.loadingSubmitButton = false;
        return;
      }

      let params = `?cost_center_id=${this.costCenterId}`;

      if(this.docking_station_id != "" && this.docking_station_id != null && this.docking_station_id >= 0){
        params += `&docking_station_id=${this.docking_station_id}`;
      }

      var response = await this.getGeoFences(params)
      if(response && response.data){
        this.stationsAndGeoFences = Array.isArray(response.data.geo_fences) ? response.data.geo_fences : [response.data.geo_fences]
      }
      this.loadingSubmitButton = false;
    },

    async handleGetBikes() {
      this.loadingBikes = true;
      if(this.costCenterId == "" || this.costCenterId == null ) {
        this.$message({
          type: "error",
          message: this.$t("dialogs.geo_fences.cost_center_not_selected"),
        });
        this.loadingBikes = false;
        return;
      }
      let params = `?cost_center_id=${this.costCenterId}`;
      let response = await this.getBikes(params)
      if(response && response.data){
        this.bikes = Array.isArray(response.data.bikes) ? response.data.bikes : [response.data.bikes]
        this.bikesWithoutLocation = [];
        response.data.bikes.forEach((bike) => {
          if(!bike.tracker || !bike.tracker.coordinates){
            this.bikesWithoutLocation.push(bike);
          }
        });
        
      }else{
        this.bikes = [];
      }
      this.loadingBikes = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.geo-fences-map {
  .custom-legend {
    flex-direction: column;
    min-width: 130px;

    img {
      height: 20px;
    }

    span {
      font-size: 14px;
    }
  }

  .geo-fence-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 20px;
  }

  .geo-fence-filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .cost-centers-filter,
    .docking-stations-filter {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }

  }
  .title-filter {
    font-size: 16px;
    color: $dark-blue;
    font-weight: 600;
    opacity: 0.5;
    line-height: 22px;
    margin-bottom: 6px;
    font-family: $avenir-next-demi;
  }
}

.bikes-container {
  padding: 5px 10px;
  display: flex;
  flex-wrap: wrap; /* Allows items to move to the next row */
  gap: 10px; /* Space between items */
}

.bike-item {
  padding: 5px 10px;
  background: rgb(238, 235, 235);
  border-radius: 5px;
  white-space: nowrap; /* Prevents text from breaking */
}

</style>
